import React from "react"

import Loadable from "react-loadable-visibility/react-loadable"
import Loading from "../components/Otros/Loading/Loading"

const LoadableComponent = React.memo(
  Loadable.Map({
    loader: {
      NotFound: () => import("../components/Otros/NotFound/NotFound"),
    },
    loading: Loading,

    render(loaded, props) {
      let NotFound = loaded.NotFound.default
      return <NotFound />
    },
  })
)

export default LoadableComponent
